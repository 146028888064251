/*
--------------------------------------------------------------------------
TW Elements is an open-source UI kit of advanced components for TailwindCSS.
Copyright © 2023 MDBootstrap.com

Unless a custom, individually assigned license has been granted, this program is free software: you can redistribute it and/or modify it under the terms of the GNU Affero General Public License as published by the Free Software Foundation, either version 3 of the License, or (at your option) any later version.
In addition, a custom license may be available upon request, subject to the terms and conditions of that license. Please contact tailwind@mdbootstrap.com for more information on obtaining a custom license.
This program is distributed in the hope that it will be useful, but WITHOUT ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more details.

If you would like to purchase a COMMERCIAL, non-AGPL license for TWE, please check out our pricing: https://tw-elements.com/pro/
--------------------------------------------------------------------------
*/

const getConnectsTemplate = (classes, attributes) => {
  return `<div class="${classes.connectContainer}" ${attributes}>
  <div class="${classes.connect}"></div>
  </div>`;
};

const getHandleTemplate = (classes, attributes) => {
  return `<div class="${classes.hand}" ${attributes}>
    <span></span>
  </div>`;
};

const getTooltipTemplate = (classes, attributes) => {
  return `
    <span class="${classes.tooltip}" ${attributes}>
      <span class="${classes.tooltipValue}"></span>
    </span>
    `;
};

export { getConnectsTemplate, getHandleTemplate, getTooltipTemplate };
